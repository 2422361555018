import React, { useEffect } from 'react';
import { navigate } from 'gatsby-link';
import Layout from '../components/layout';

export default () => {
  const browser = typeof window !== 'undefined';

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 5000);
  }, []);

  return browser && <Layout>Not found</Layout>;
};
